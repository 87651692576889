<style lang='scss' scoped>
@include form-page;
.backup-form {
  margin: 30px auto;
  max-width: 960px;
}
.table {
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>
<template>
  <div>
    <el-form class="backup-form" ref="form" :model="form" :rules="rules" label-width="180px">
      <el-form-item v-if="isRemote" :label="$t('backup.backupServerUrl')" prop="backupServerUrl">
        <el-input v-model="form.backupServerUrl" :placeholder="$t('backup.backupServerUrlPla')" maxlength="521">
        </el-input>
      </el-form-item>
      <el-form-item v-if="isRemote" :label="$t('personnalInfo.username')" prop="username">
        <el-input v-model="form.username" :placeholder="$t('backup.usernamePla')" maxlength="128">
        </el-input>
      </el-form-item>
      <el-form-item v-if="isRemote" :label="$t('personnalInfo.password')" prop="password">
        <el-input v-model="form.password" show-password :placeholder="$t('backup.passwordPla')" maxlength="128">
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('backup.backupContent')" prop='backupContent'>
        <el-checkbox-group v-model="form.backupContent">
          <el-checkbox label="isBackupData" disabled>{{ $t('backup.data') }}</el-checkbox>
          <el-checkbox label="isBackupFirware">{{ $t('sidebar.firmware') }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item :label="$t('backup.automaticBackup')" prop="isAutomaticBackup">
        <el-switch
          v-model="form.isAutomaticBackup"
          :active-value="1"
          :inactive-value="0"
          @change="isAutomaticBackupChange"
        ></el-switch>
        <select-repeating-time
          v-if="form.isAutomaticBackup == 1"
          :beginTime.sync="form.beginTime"
          :repeateType.sync="form.repeateType"
          :repeateTime.sync="form.repeateTime"
        ></select-repeating-time>
      </el-form-item>
      <el-form-item>
        <el-button @click="cancel">{{$t('cancel')}}</el-button>
        <el-button type="primary" @click="save">{{$t('save')}}</el-button>
        <el-button type="primary" @click="backupNow">{{$t('backup.backupNow')}}</el-button>
      </el-form-item>
    </el-form>
    <div class="page-tools">
      <el-button
        type="primary"
        icon="el-icon-delete iconfont icon-delete"
        :disabled="!tableSelection.length"
        @click="delItems()"
        >{{ $t('delete') }}
      </el-button>
    </div>
    <table-pagination
      class="table"
      :tableData="tableData"
      :columnData="columnData"
      selectionShow
      @selection-change="selectionChange"
      optionShow
      :total="total"
      :currentPage.sync="tabelSearch.currentPage"
      :pageSize.sync="tabelSearch.pageSize"
      @changeCurrentPage="getTabelData"
      :paginationHide="!isRemote"
    >
      <template slot="option" slot-scope="scope">
        <el-tooltip
          effect="dark"
          :content="$t('backup.recover')"
          placement="top"
          :open-delay="1000"
        >
          <el-button
            type="text"
            class="btn-p0"
            @click="recoverItem(scope.row.id)"
            icon="el-icon-edit iconfont icon-recover"
          ></el-button>
        </el-tooltip>
      </template>
    </table-pagination>
    <BackupNow
      v-if="backupNowVisible"
      :visible.sync="backupNowVisible"
      :info="{ row: form, isRemote }"
      @submit="openBackupStatus(true)"
    />
  </div>
</template>

<script>
import { TablePagination, SelectRepeatingTime } from '@/components'
import { backupSearch, backupConfigByType, backupConfig, backupRecoverById, backupRemove } from '@/api/backup'
import BackupNow from './backup-now.vue'

export default {
  name: 'backupDetails',
  components: {
    TablePagination,
    SelectRepeatingTime,
    BackupNow,
  },
  props: {
    option: Object
  },
  data() {
    return {
      total: 0,
      tabelSearch: {
        backupType: this.option.activeName,
        currentPage: 1,
        pageSize: 10,
      },
      oldForm: {},
      form: {
        id: undefined,
        backupType: this.option.activeName,
        backupServerUrl: '',
        username: '',
        password: '',
        backupContent: ['isBackupData'],
        isBackupData: 1,
        isBackupFirware: 0,
        isAutomaticBackup: 0,
        beginTime: this.$nowTime() + 600000,
        repeateType: 1,
        repeateTime: null,
      },
      rules: {
        backupServerUrl: {
          validator: (rule, value, callback) => {
            if (value === '') {
              callback(new Error(this.$t('backup.backupServerUrlPla')));
            } else {
              const regexp = /^(((http|https|ftp):\/\/)|(www\.))+(([a-zA-Z0-9._-]+\.[a-zA-Z]{2,6})|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]{1}|6553[0-5]))?(\/[a-zA-Z0-9&%_./-~-]*)?$/
              if (regexp.test(value)) {
                callback();
              } else {
                callback(new Error(this.$t('backup.invalidFormat')));
              }
            }
          },
          trigger: 'blur',
        },
        backupContent: {
          required: true,
          message: this.$t('backup.backupContentRule'),
          trigger: 'blur',
        },
      },
      tableSelection: [],
      tableData: [],
      columnData: [
        {
          label: this.$t('backup.fileName'),
          prop: 'backupFileName',
          minWidth: 160,
        },
        {
          label: this.$t('backup.backupTime'),
          prop: 'backupTime',
          filter: 'dateFormat',
          minWidth: 160,
        },
      ],
      backupNowVisible: false,
    }
  },
  computed: {
    isRemote() {
      return this.option.activeName == 'remote'
    }
  },
  watch: {
    'form.backupContent'(val) {
      const list = ["isBackupData", "isBackupFirware"]
      list.forEach(e => this.form[e] = val.includes(e) ? 1 : 0)
    },
  },
  mounted() {
    this.getData()
    this.getTabelData()
  },
  methods: {
    getData() {
      backupConfigByType(this.tabelSearch.backupType).then(({data}) => {
        const form = data.result.rows[0]
        if(form) {
          if(this.isRemote) {
            this.form.backupServerUrl = form.backupServerUrl
            this.form.username = form.username
            this.form.password = form.password
          }
          this.form.id = form.id
          this.form.isBackupData = form.isBackupData
          this.form.isBackupFirware = form.isBackupFirware
          this.form.isAutomaticBackup = form.isAutomaticBackup
          this.form.beginTime = form.beginTime
          this.form.repeateType = form.repeateType
          this.form.repeateTime = form.repeateTime
          // backupContent
          const backupContent = []
          this.form.isBackupData && backupContent.push('isBackupData')
          this.form.isBackupFirware && backupContent.push('isBackupFirware')
          this.form.backupContent = backupContent
        }
        // oldData
        this.oldForm = { ...this.form }
      })
    },
    getTabelData() {
      backupSearch(this.tabelSearch).then(({data}) => {
        this.tableData = data.result.rows[0].list
        this.total = data.result.rows[0].total
      })
    },
    selectionChange(val) {
      this.tableSelection = val
    },
    delItems() {
      let strTip = this.$t('backup.deleteTip')
      if(this.isRemote) strTip += '<br />'+ this.$t('backup.deleteTip2')
      this.$confirm(strTip, this.$t('tip'), {
        dangerouslyUseHTMLString: true,
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning',
      }).then(() => {
        const ids = this.tableSelection.map(e => e.id)
        backupRemove(ids).then(({data}) => {
          this.$message.success(data.message)
          this.getTabelData()
        })
      }).catch(() => {})
    },
    recoverItem(id) {
      this.$confirm(this.$t('backup.recoverTip'), this.$t('tip'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning',
      }).then(() => {
        backupRecoverById(id).then(() => {
          this.openBackupStatus()
        })
      }).catch(() => {})
    },
    cancel() {
      this.form = { ...this.oldForm }
    },
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let params = {
            backupType: this.form.backupType,
            isBackupData: this.form.isBackupData,
            isBackupFirware: this.form.isBackupFirware,
            isAutomaticBackup: this.form.isAutomaticBackup,
            beginTime: this.form.beginTime,
            repeateType: this.form.repeateType,
            repeateTime: this.form.repeateTime,
          }
          if(this.form.id) params.id = this.form.id
          if(this.isRemote) {
            params = {
              backupServerUrl: this.form.backupServerUrl,
              username: this.form.username,
              password: this.form.password,
              ...params,
            }
          }
          backupConfig(params).then(({data}) => {
            this.$message.success(data.message)
            this.getData()
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    backupNow() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.backupNowVisible = true
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    isAutomaticBackupChange(val) {
      if(val == 1) {
        this.form.beginTime = this.form.beginTime ? this.form.beginTime : this.$nowTime() + 600000
        this.form.repeateType = this.form.repeateType ? this.form.repeateType : 1
        this.form.repeateTime = this.form.repeateTime == 0 ? null : this.form.repeateTime
      }
    },
    openBackupStatus(isBackup) {
      const info = {}
      if(isBackup) {
        info.code = 100000
        info.message = this.$t('backup.backingTip')
        info.cancelShow = true
      } else {
        info.code = 110000
        info.message = this.$t('backup.recoveringTip')
      }
      this.$store.commit('session/SET_DIALOG_BACKUP_STATUS_INFO', info)
    },
  }
}
</script>

<style>

</style>