<style lang='scss' scoped>
.el-tab-pane {
  background-color: white;
}
</style>
<template>
  <div class="page">
    <div class="page-main bg-transparent">
      <el-tabs v-model="backupDetailsOption.activeName" @tab-click="changeTab">
        <el-tab-pane :label="$t('backup.localBackup')" name="local" lazy>
          <BackupDetails :option="backupDetailsOption" />
        </el-tab-pane>
        <el-tab-pane :label="$t('backup.remoteBackup')" name="remote" lazy>
          <BackupDetails :option="backupDetailsOption" />
        </el-tab-pane>
        <el-tab-pane :label="$t('edm022')" name="quick" lazy>
          <QuickBackup/>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BackupDetails from './components/backupDetails.vue'
import QuickBackup from './QuickBackup.vue'

export default {
  name: 'Backup',
  components: {
    BackupDetails,QuickBackup
  },
  data() {
    return {
      backupDetailsOption: {
        activeName: 'local',
      }
    }
  },
  computed: {
    ...mapState('memory', ['backupTab'])
  },
  created() {
    this.backupTab && (this.backupDetailsOption.activeName = this.backupTab)
  },
  beforeRouteLeave (to, from, next) {
    // 重置成 local
    this.$store.commit('memory/SET_BACKUP_TAB', 'local')
    next()
  },
  methods: {
    changeTab() {
      this.$store.commit('memory/SET_BACKUP_TAB', this.backupDetailsOption.activeName)
    }
  }
}
</script>

<style>

</style>