<template>
  <el-dialog :title="$t('backup.backupNow')" :visible.sync="visible" width="960px" :before-close="handleClose"
    :close-on-click-modal="false">
    <span slot="footer">
      <el-button @click="handleClose">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submit" v-if="!quick">{{ $t('confirm') }}</el-button>
      <el-button type="primary" @click="quickSubmit" v-if="quick">{{ $t('confirm') }}</el-button>
    </span>
    <el-form class="form" ref="form" :model="form" :rules="rules" label-width="auto">
      <el-form-item :label="$t('backup.fileName')" prop="backupFileName">
        <el-input v-model="form.backupFileName" :placeholder="$t('backup.fileNamePla')" maxlength="128"></el-input>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { backupExecute } from '@/api/backup'

export default {
  name: 'backup-now',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    quick: {
      type: Boolean,
      default: false
    },
    info: Object
  },
  data() {
    return {
      form: {
        backupFileName: 'edm_backup_' + this.$dateFormat(this.$nowTime(), 'YYYYMMDDHHmm', false) +".tar.gz",
      },
      rules: {
        backupFileName: {
          required: true,
          message: this.$t('backup.fileNameRule'),
          trigger: 'blur',
        },
      },
    }
  },
  methods: {
    quickSubmit() {
      this.$emit("submit", {customFileName: this.form.backupFileName})
      this.handleClose()
    },
    // 取消
    handleClose() {
      this.$emit('update:visible', false)
    },
    // 确定
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let params = {
            ...this.form,
            backupType: this.info.row.backupType,
            isBackupData: this.info.row.isBackupData,
            isBackupFirware: this.info.row.isBackupFirware,
            isAutomaticBackup: this.info.row.isAutomaticBackup,
            beginTime: this.info.row.beginTime,
            repeateType: this.info.row.repeateType,
            repeateTime: this.info.row.repeateTime,
          }
          if (this.info.isRemote) {
            params = {
              backupServerUrl: this.info.row.backupServerUrl,
              username: this.info.row.username,
              password: this.info.row.password,
              ...params,
            }
          }
          backupExecute(params).then(() => {
            this.$emit("submit")
            this.handleClose()
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>