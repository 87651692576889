<template>
  <div class="page">
    <div class="sub-page">


      <h3>{{ $t('edm016') }}</h3>
      <div class="line1">
        <div>
          <a>{{ $t('edm017') }}</a>
        </div>
        <div class="grid-content ">
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="data" disabled>{{ $t('edm018') }}</el-checkbox>
            <el-checkbox label="firmware">{{ $t('edm019') }}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div>
          <el-button @click="openConfirmDialog" type="primary" class="button">{{ $t('edm020') }}</el-button>
        </div>
      </div>
      <h3>{{ $t('edm021') }}</h3>
      <div class="line2">
        <div class="upload-wrapper">
          <div class="flex-item">
            <el-upload
                multiple
                drag
                action="/backup/uploadRecovery"
                :on-preview="handlePreview"
                :show-file-list="true"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :limit="1"
                :on-exceed="handleExceed"
                :on-success="afterSuccess"
                :file-list="fileList"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">{{ $t('edm026') }} <em>{{ $t('edm013') }}</em></div>
            </el-upload>
          </div>

        </div>
        <div class="button-wrapper">
          <div>
            <el-button @click="executeRestore" :disabled="fileId == null" type="primary" class="button">{{ $t('edm014') }}
            </el-button>
          </div>
        </div>
      </div>
      <BackupNow
          v-if="backupNowVisible"
          :visible.sync="backupNowVisible"
          :quick="true"
          @submit="downloadBackupFile"
      />
    </div>
  </div>
</template>

<script>
import axios from '@/plugins/axios'
import {downloadFile} from '@/plugins/methods'
import BackupNow from './components/backup-now.vue'

export default {
  name: 'Backup',
  components: {
    BackupNow
  },
  data() {
    return {
      checkList: ['data'],
      fileList: [],
      fileId: null,
      backupNowVisible: false
    }
  },
  computed: {},
  created() {
  },
  methods: {
    openConfirmDialog() {
      this.backupNowVisible = true;
    },
    downloadBackupFile({customFileName}) {
      console.log("xxx" + customFileName)
      let url = '/backup/quickBackup'
      if (this.checkList.length == 1) {
        // eslint-disable-next-line quotes
        url += "?idisBackupFirware=0"
      } else {
        url += '?idisBackupFirware=1'
      }
      axios.get(url, {responseType: 'blob'})
          .then((res) => downloadFile(res, customFileName))
    },
    handleRemove() {
      this.fileList = []
      this.fileId = null
    },
    beforeRemove() { },
    handleExceed() { },
    afterSuccess(response) {
      this.fileId = response.result.rows[0]
      console.log(this.fileId)
    },
    handlePreview() {},
    executeRestore() {
      this.$confirm(this.$t('edm015'), this.$t('tip'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning',
      }).then(() => {
        axios.post(`/backup/recovery/${this.fileId}`)
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  .line1 > div {
    padding-right: 100px;
  }

  .line1 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .line2 {
    .upload-wrapper {
      display: flex;
      justify-content: center;
      ::v-deep .el-upload-dragger {
        width: 50rem;
      }
    }

    .button-wrapper {
      padding-top: 1rem;
      display: flex;
      justify-content: center;
      div{
        width: 50rem;
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }

}
</style>
